
<!--提报记录：提报记录-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">提报记录</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" @tab-click="getDeviceListById">
            <el-tab-pane label="定期更换" name="first">
              <div style="text-align:right;padding-bottom:10px">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    @click="addTeamUserShow()"
                >
                  添加
                </el-button>
              </div>
                <div class="table-box">
                  <el-table
                      :data="replacementData"
                      border
                      highlight-current-row
                      style="width: 100%"
                  >
                    <el-table-column label="设备名称" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.name }}
                      </template>
                    </el-table-column>
                    <el-table-column label="更换项目" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.project}}
                      </template>
                    </el-table-column>
                    <el-table-column label="备注" align="center">
                      <template slot-scope="scope">
                        <!--                          <el-switch-->
                        <!--                              v-model="scope.row.state === 1 ? true : false"-->
                        <!--                              @change="handleSwitch(scope.row)"-->
                        <!--                          >-->
                        <!--                          </el-switch>-->
                      </template>
                    </el-table-column>
                    <el-table-column label="提报时间" align="center">
                      <template slot-scope="scope">
                        {{scope.row.date}}
                      </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                      <template slot-scope="scope">
                        <span style="font-weight: bold;" :style="{color: scope.row.result === '通过' ? 'green' : 'grey' }">{{scope.row.result}}</span>
                      </template>
                    </el-table-column>
                    <!--操作-->
                    <el-table-column label="操作" header-align="center" align="center" width="240">
                      <template slot-scope="scope">
                        <el-button type="primary"  size="mini" @click="checkReplacement(scope.row,2)">
                          查看
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!--分页-->
                <div class="pagination-box">
                  <el-pagination
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="replacement.count"
                      :total="replacementCount"
                      :current-page="replacement.page"
                      layout="total, sizes, prev, pager, next, jumper"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                  />
                </div>
            </el-tab-pane>
            <el-tab-pane label="异常提报" name="second">
                <div style="text-align:right;padding-bottom:10px">
                  <el-button
                      type="primary"
                      icon="el-icon-plus"
                      @click="addAbnormal()"
                  >
                    添加
                  </el-button>
                </div>
                <div class="table-box">
                  <el-table
                      :data="abnormal"
                      border
                      highlight-current-row
                      style="width: 100%"
                  >
                    <el-table-column label="设备名称" align="center" width="300">
                      <template slot-scope="scope">
                        {{ scope.row.name }}
                      </template>
                    </el-table-column>
                    <!--性别-->
                    <el-table-column label="异常待机时长" align="center" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.actualTime }}
                      </template>
                    </el-table-column>
                    <!--角色-->
                    <el-table-column label="提报原因" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.reason }}</span>
                      </template>
                    </el-table-column>
                    <!--手机号-->
                    <el-table-column label="提报人" align="center" width="150">
                      <template slot-scope="scope">
                        <span>{{ scope.row.person }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="提报时间" align="center" width="170">
                      <template slot-scope="scope">
                        <span>{{ scope.row.date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="状态" align="center" width="150">
                      <template slot-scope="scope">
                        <span :style="{color:scope.row.state === 0 ? '#EA000E' : scope.row.state === 1 ? '#F7B515' : scope.row.state === 2 ? '#37C239' :'#777474' }">{{scope.row.state === 0 ? '未提报' : scope.row.state === 1 ? '未审核' : scope.row.state === 2 ? '已审核' :'超时取消'}}</span>
                      </template>
                    </el-table-column>
                    <!--操作-->
                    <el-table-column label="操作" align="center" width="170">
                      <template slot-scope="scope">
                        <el-button
                            type="primary"
                            size="mini"
                            @click="checkAbnormal(scope.row)"
                        >
                          查看
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!--分页-->
                <div class="pagination-box">
                  <el-pagination
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="abnormalForm.count"
                      :total="abnormalCount"
                      :current-page="abnormalForm.page"
                      layout="total, sizes, prev, pager, next, jumper"
                      @size-change="handleSizeChange2"
                      @current-change="handleCurrentChange2"
                  />
                </div>
            </el-tab-pane>
            <el-tab-pane label="故障报修" name="three">
              <div style="text-align:right;padding-bottom:10px">
                <el-button
                    v-if="activeName === 'three'"
                    type="primary"
                    icon="el-icon-plus"
                    @click="addFault()"
                >
                  添加
                </el-button>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="faultList"
                    element-loading-text="Loading"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--昵称-->
                  <el-table-column label="设备名称" align="center" >
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="报修类型" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="维修人员" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{ scope.row.person }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" label="提报时间" align="center" width="170">
                    <template slot-scope="scope">
                      <span>{{ scope.row.date }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" label="状态" align="center" width="150">
                    <template slot-scope="scope">
                <span style="font-weight: bold;" :style="{color: scope.row.result === '通过' ? 'green' : 'grey' }">
                {{scope.row.result}}
              </span>
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column label="操作" align="center" width="170">
                    <template slot-scope="scope">
                      <el-button
                          type="primary"
                          size="mini"
                          @click="checkFault(scope.row)"
                      >
                        查看
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="faultForm.count"
                    :total="faultCount"
                    :current-page="faultForm.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange3"
                    @current-change="handleCurrentChange3"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!--弹出框：添加定期更换-->
    <el-dialog
        :visible.sync="TeamUserShow"
        title="添加定期更换"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close1()"
    >
      <el-form label-position="left" ref="inviteForm" label-width="110px" :model="invite" :rules="rules.inviteRules">
        <el-form-item label="设备名称" prop="deviceId">
          <el-select
              v-model="invite.deviceId"
              placeholder="请选择设备"
              style="width: 100%"
          >
            <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="更换项目" prop="project">
          <el-select
              v-model="invite.project"
              placeholder="请选择更换项目"
              style="width: 100%"
          >
            <el-option
                v-for="item in reasonList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              v-model="invite.remark"
              show-word-limit
              clearable
              placeholder="请填写备注"
              maxlength="100"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
          提交
        </el-button>
      </div>
    </el-dialog>
    <!--弹出框：添加异常提报-->
    <el-dialog
        :visible.sync="abnormalShow"
        title="添加异常提报"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close2()"
    >
      <el-form label-position="left" ref="abnormalRules" label-width="110px" :model="abnormalData" :rules="rules.abnormalRules">
        <el-form-item label="设备名称" prop="deviceId">
          <el-select
              v-model="abnormalData.deviceId"
              placeholder="请选择设备"
              style="width: 100%"
              @change="handleDevice"
          >
            <el-option
                v-for="item in abnormalDeviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="额定待机时长" prop="phone">
          <span>{{ratedTime}}</span>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
              v-model="abnormalData.startTime"
              :picker-options="pickerOptions"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
              v-model="abnormalData.endTime"
              :picker-options="pickerOptionsEnd"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作时长" prop="workTime">
          <el-input-number
              v-model="abnormalData.workTime"
              show-word-limit
              :max="1000000000000"
              :min="0"
              :precision="0"
              placeholder="请输入工作时长"
          />
          <span style="margin-left: 10px">分钟</span>
        </el-form-item>
        <el-form-item label="异常待机时长" prop="actualTime">
          <el-input-number
              v-model="abnormalData.actualTime"
              show-word-limit
              :max="1000000000000"
              :min="0"
              :precision="0"
              placeholder="请输入异常待机时长"
          />
          <span style="margin-left: 10px">分钟</span>
        </el-form-item>
        <el-form-item label="提报原因" prop="reason">
          <el-select
              v-model="abnormalData.reason"
              multiple
              placeholder="请选择提报原因"
              style="width: 100%"
          >
            <el-option
                v-for="item in abnormalReasonList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="">
          <el-input
              v-model="abnormalData.remark"
              show-word-limit
              clearable
              placeholder="请填写备注"
              maxlength="100"
          />
          <!--     size="medium"       -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="abnormalShow = false">取 消</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addAbnormalBtn()">
          提交
        </el-button>
      </div>
    </el-dialog>
    <!--弹出框：添加故障报修-->
    <el-dialog
        :visible.sync="faultShow"
        title="添加故障报修"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close1()"
    >
      <el-form label-position="left" ref="faultRules" label-width="110px" :model="faultDate" :rules="rules.faultRules">
        <el-form-item label="设备名称" prop="deviceId">
          <el-select
              v-model="faultDate.deviceId"
              placeholder="请选择设备"
              clearable
              style="width: 100%"
          >
            <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="故障类型" prop="project">
          <el-select
              v-model="faultDate.project"
              placeholder="请选择更换项目"
              style="width: 100%"
              clearable
          >
            <el-option
                v-for="item in repairTypes"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="维修人员" class="person" prop="">
          <span class="btn" v-if="!faultDate.name" @click="handlePerson">非必选<i class="el-icon-arrow-right"></i></span>
          <span class="btn" v-else style="color:#606266" @click="handlePerson">{{ faultDate.name }}<i class="el-icon-arrow-right"></i></span>
        </el-form-item>
        <el-form-item label="" v-if="faultDate.deviceId" class="record" prop="">
          <div class="history" @click="goHistory">
            <img src="@/assets/img/icon-device.png" alt="历史维修记录" />
            <span>历史维修记录</span>
          </div>
        </el-form-item>
        <el-form-item label="故障问题" prop="repairInfo">
          <el-input
              v-model="faultDate.repairInfo"
              show-word-limit
              clearable
              type="textarea"
              :rows="4"
              placeholder="请输入故障问题 （最多150字）"
              maxlength="150"
          />
        </el-form-item>
        <div class="pic-and-video-wrapper">
          <div v-for="(img, index) in pics" :key="index" class="preview">
            <el-image
                style="width: 100%"
                :src="img"
                :preview-src-list="pics">
            </el-image>
            <el-button class="van-btton" icon="el-icon-delete-solid" type="text"  @click="removeImage(index)" />
          </div>
          <div class="btn" @click="selectImage" v-show="pics.length < 3">
            <img src="@/assets/img/add-pic.png" alt="add pic" />
            <span style="font-size: 12px;">添加照片</span>
            <input v-show="false" ref="imgInput" type="file" accept="image/*" onclick="event.cancelBubble = true"
                   multiple @input="pickImage" />
          </div>
          <div class="btn" v-if="!!video">
            <span @click="playVideo">点击播放</span>
            <div class="closeVideo-btn" @click="removeVideo">
              <img src="@/assets/img/img_2.png" />
            </div>
          </div>
          <div class="btn" @click="selectVideo" v-show="!video">
            <img src="@/assets/img/add-video.png" alt="add video" />
            <span style="font-size: 12px;">添加视频</span>
            <input v-show="false" ref="videoInput" type="file" accept="video/*" onclick="event.cancelBubble = true"
                   multiple @input="pickVideo" />
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="faultShow = false">取 消</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addFaultBtn()">
          提交
        </el-button>
      </div>
    </el-dialog>
    <!-- 播放弹出框 -->
    <el-dialog :visible.sync="showVideo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close2()">
      <video ref="videoShow" style="width:100%;height:100%" controls>
        <source :src="videoSrc" type="video/mp4">。
      </video>
    </el-dialog>
<!--  人员选择  -->
    <el-dialog :visible.sync="showPerson"
               title="人员选择"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               width="30%"
               @close="close3()">
      <div>
        <div v-for="(item,index) in teamList" :key="index">
          <div v-if="item.orderType === '1'" class="item" @click="handlePersonSelect(item)">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/img/icon-pen.png" alt="">
              <span>{{ item.cName }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
<!--  历史维修记录  -->
    <el-dialog :visible.sync="historyShow"
               title="历史维修记录"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               width="30%"
               @close="close4()">
      <div>
        <div v-for="(item,index) in historyList" :key="index">
         <div class="list-item">
            <div class="item-left">
              <div>
                <img src="@/assets/img/icon-tool.png" alt="">
              </div>
              <div>
                <div class="name">{{item.device}}</div>
                <div>维修人员:{{item.repairPerson}}</div>
              </div>
            </div>
           <div class="item-right">
             <div class="name">{{item.repairType}}</div>
             <div>{{item.repairTime}}</div>
           </div>
         </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api';
import { base64toFile } from '@/utils/file';
import moment from "moment";

export default {
  name: "reportList",
  data() {
    return {
      historyList: [],
      historyShow:false,
      showPerson:false,// 人员选择弹窗
      abnormal: [],// 异常提报
      abnormalShow:false,
      abnormalForm: {
        page: 1,
        count: 10,
        type: 2
      },
      abnormalData: {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: ''
      },
      abnormalDeviceList: [],
      abnormalReasonList: [],
      abnormalCount: 0,
      ratedTime: '',
      replacementData: [],// 定期更换
      loading:false,
      replacement: {
        page: 1,
        count: 10,
        type: 1
      },
      replacementCount: 0,
      TeamUserShow: false,
      addUserBtnLoading: false,
      invite: {
        deviceId: '',
        remark: '',
        project: ''
      },
      deviceList:[],
      reasonList: [],
      totalCount: 0,
      faultList: [],// 异常提报
      faultShow: false,
      faultDate: {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      },
      faultForm: {
        page: 1,
        count: 10,
        type: 3
      },
      faultCount: 0,
      pics: [],
      video: '',
      voice: null,
      showVideo: false,
      videoSrc: null,
      repairTypes: ['电气', '机械', '调试', '其它'],
      teamList: [],
      activeName: 'first',
      activeName2: 'first',
      activeName3: 'first',
      pickerOptions: {
        disabledDate: time => {
          const year = new Date().getFullYear(); // 获取当前年份
          const januaryFirst = new Date(year, 0, 1); // 当年一月一号零时零点
          if (this.abnormalData.endTime) {
            return (time.getTime() > new Date(this.abnormalData.endTime).getTime() || time.getTime() < januaryFirst.getTime())
          } else {
            return time.getTime() < januaryFirst.getTime() || time.getTime() > Date.now(); // 禁用未来时间
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.abnormalData.startTime) {
            return (time.getTime() < new Date(this.abnormalData.startTime).getTime() || time.getTime() > Date.now())
          }else {
            const year = new Date().getFullYear(); // 获取当前年份
            const januaryFirst = new Date(year, 0, 1); // 当年一月一号零时零点
            return time.getTime() < januaryFirst.getTime() || time.getTime() > Date.now(); // 禁用未来时间
          }

        },
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }],
          project: [{ required: true, message: '请选择更换目录', trigger: 'change' }],
          remark: [{ required: true, trigger: 'blur', message: '请填写备注' }],
        },
        abnormalRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }],
          startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
          endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
          workTime: [
            { required: true, trigger: 'blur', message: '请输入工作时长' },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],
          reason: [{ required: true, message: '请选择提报原因', trigger: 'change' }],
          actualTime: [
            { required: true, trigger: 'blur', message: '请输入工作时长' },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],

          remark: [{ required: true, trigger: 'blur', message: '请填写备注' }],
          // phone: [
          //   {
          //     required: true,
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       valid.checkNull(rule, value, callback, '请输入手机号', false);
          //       valid.checkByRegex(rule, value, callback, valid.REG_PHONE, '手机号不合法');
          //     },
          //   },
          // ],
          teamGroupId: [{ required: true, message: '请选择班组', trigger: 'change' }],
          teamValues: [{ required: true, message: '请选择班组', trigger: 'change' }],
        },
        faultRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }],
          project: [{ required: true, message: '请选择更换目录', trigger: 'change' }],
          repairInfo: [{ required: true, trigger: 'blur', message: '请输入故障问题' }],
        },
      },
    };
  },
  computed: {
  },
  created() {
    console.log(this.$store.state.userInfo.roleType)
    console.log(this.$store.state.userInfo.userId)
    this.getTeamUser();
    this.getList()
    this.getAbnormal()
    this.getFault()
    console.log(this.$store.state.userInfo.roleIds)
  },
  mounted () {
    console.log(this.$store.state)
    console.log(this.$store.state.userInfo.userId)
  },
  methods: {
    getTeamUser() {
      API.getRecordList(this.replacement).then(res => {
        this.replacementData = res.message.data.list
        this.replacementCount = res.message.data.totalCount
      })
    },
    getList() {
      API.repairDeviceList().then(res => {
        this.deviceList.push(...res.message.data)
      })
      API.reasonList({type:14}).then((res) => {
        this.reasonList.push(...res.message.data)
      })
      API.abnormaDeviceList().then(res => {
        this.abnormalDeviceList.push(...res.message.data)
      })
      API.reasonList({type:13}).then((res) => {
        this.abnormalReasonList.push(...res.message.data)
      })
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
    },
    getAbnormal() {
      API.getRecordList(this.abnormalForm).then((response) => {
        this.abnormal = response.message.data.list;
        this.abnormalCount = response.message.data.totalCount;
      });
    },
    getFault() {
      API.getRecordList(this.faultForm).then((response) => {
        this.faultList = response.message.data.list;
        this.faultCount = response.message.data.totalCount;
      });
    },
    handleDevice(e) {
      this.abnormalDeviceList.forEach(item => {
        if (item.id === e) {
          this.ratedTime = item.time + 'min'
          this.abnormalData.ratedTime = item.time
        }
      })
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    checkReplacement(row) {
      this.$router.push({ path: 'replacementDetail', query: {id:row.id}});
    },
    checkAbnormal(row) {
      this.$router.push({ path: 'abnormalDetail', query: {id:row.id}});
    },
    checkFault(row) {
      this.$router.push({ path: 'faultDetail', query: {deviceRepairId:row.id}});
    },
    addTeamUserShow() {
      this.invite = {
        deviceId: '',
        remark: '',
        project: ''
      }
      this.TeamUserShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.inviteForm.clearValidate();
      });
    },
    close1() {
      this.TeamUserShow = false;
    },
    close2() {
      this.abnormalShow = false;
    },
    close3() {
      this.showPerson = false;
    },
    close4() {
      this.historyShow = false;
    },
    addAbnormal() {
      this.abnormalData = {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: ''
      }
      this.ratedTime = ''
      this.abnormalShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.abnormalRules.clearValidate();
      });
    },
    addFault() {
      this.faultDate = {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      }
      this.pics=[]
      this.video= ''
      this.voice= null
      this.showVideo= false
      this.videoSrc= null
      this.faultShow = true
      this.$nextTick(() => {
        // 清除验证
        this.$refs.faultRules.clearValidate();
      });
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          API.submitReplacement(this.invite)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.TeamUserShow = false;
                  this.getTeamUser();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    addAbnormalBtn() {
      const time1 = Date.parse(new Date(this.abnormalData.startTime))
      const time2 = Date.parse(new Date(this.abnormalData.endTime))
      if (time1 > time2) {
        this.$message({
          message: '开始时间不能大于结束时间',
          // showClose: true,
          type: 'warning',
        });
        return
      }
      this.$refs.abnormalRules.validate((v) => {
        if (v) {
          this.abnormalData.startTime = this.dateFmt(this.abnormalData.startTime)
          this.abnormalData.endTime = this.dateFmt(this.abnormalData.endTime)
          this.abnormalData.actualTime = String(this.abnormalData.actualTime)
          this.abnormalData.reason = this.abnormalData.reason.join(';')
          this.addUserBtnLoading = true;
          API.SubmissionCreate(this.abnormalData)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.abnormalShow = false;
                  this.getAbnormal();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    addFaultBtn() {
      this.$refs.faultRules.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          const formdata = new FormData();
          formdata.append('repairType', this.faultDate.project)
          formdata.append('orderType', "1")
          if (this.faultDate.userId === undefined) {
            this.faultDate.userId = ''
            formdata.append('userId', this.faultDate.userId)
          } else {
            formdata.append('userId', this.faultDate.userId)
          }

          formdata.append('deviceId', this.faultDate.deviceId)
          formdata.append('repairInfo', this.faultDate.repairInfo)
          this.pics.forEach((pic, index) => {
            formdata.append(`pic${index + 1}`, base64toFile(pic))
          });
          if (this.video) {
            formdata.append('vid', base64toFile(this.video));
          }
          API.submitBaoxiu(formdata)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.faultShow = false;
                  this.getFault();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    handlePersonSelect(row) {
      this.faultDate.userId = row.id
      this.faultDate.name = row.cName
      this.showPerson = false
    },
    goHistory () {
      this.historyList = []
      API.getHistoryMantainList({count: 100,page: 0,printTypeId: this.faultDate.deviceId}).then(res => {
        console.log(res.message.data.list)
        this.historyList.push(...res.message.data.list)
      })
      this.historyShow = true

      // const id = this.faultDate.deviceId;
      // this.$router.push(`/history-mantain-list/${id}`);

    },
    back() {
      this.$router.go(-1);
    },
    removeImage (index) {
      this.pics.splice(index, 1);
    },
    pickImage () {
      let input = this.$refs.imgInput
      if (input.files.length + this.pics.length > 3) {
        this.$message({
        message: '最多上传3张照片',
        // showClose: true,
        type: 'warning',
      });
        return;
      }
      for (let i = 0; i < input.files.length; i++) {
        if(input.files[i]) {
          //在此限制图片的大小
          let imgSize = input.files[i].size;
          //35160  计算机存储数据最为常用的单位是字节(B)
          //在此处我们限制图片大小为2M
          if(imgSize>50*1024*1024){
            this.$message({
              message: '上传的图片大于50M,请重新选择',
              // showClose: true,
              type: 'warning',
            });
            return;
          }
          let reader = new FileReader
          reader.onload = e => {
            this.pics.push(e.target.result)
          }
          reader.readAsDataURL(input.files[i])
        }
      }
      this.$refs.imgInput.value = null;
    },
    selectVideo () {
      this.$refs.videoInput.click()
    },
    removeVideo () {
      this.video = null;
      this.videoSrc = null;
    },
    //播放视频
    playVideo () {
      this.showVideo = true;
    },
    selectImage () {
      this.$refs.imgInput.click()
    },
    pickVideo () {
      let input = this.$refs.videoInput
      if (input.files.length > 0) {
        //在此限制图片的大小
        let videoSize = input.files[0].size;
        //35160  计算机存储数据最为常用的单位是字节(B)
        //在此处我们限制图片大小为2M
        if(videoSize>50*1024*1024){
          this.$message({
            message: '上传的视频大于50M,请重新选择',
            // showClose: true,
            type: 'warning',
          });
          return;
        }
        this.video = null
        this.videoSrc = null
        let reader = new FileReader
        reader.onload = e => {
          this.video = e.target.result;
          this.videoSrc = e.target.result;
        }
        reader.readAsDataURL(input.files[0])
      }
      this.$refs.videoInput.value = null
    },
    handlePerson() {
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
      this.showPerson = true
    },
    getDeviceListById(tab) {
      console.log(tab)
      // if (tab.name === 'second') {
      //   this.replacement.page = 1
      //   this.replacement.type = 1
      //   this.replacement.count = 10
      //   this.getTeamUser();
      // } else if (tab.name === 'three'){
      //   this.replacement.page = 1
      //   this.replacement.type = 2
      //   this.replacement.count = 10
      //   this.getTeamUser();
      // }
      //
      // console.log(tab.name)
    },
    getDeviceListById2() {

    },
    getDeviceListById3() {

    },
    handleSizeChange(val) {
      this.replacement.count = val;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.replacement.page = val ;
      this.getTeamUser();
    },
    handleSizeChange2(val) {
      this.abnormalForm.count = val;
      this.getAbnormal();
    },
    handleCurrentChange2(val) {
      this.abnormalForm.page = val ;
      this.getAbnormal();
    },
    handleSizeChange3(val) {
      this.faultForm.count = val;
      this.getFault();
    },
    handleCurrentChange3(val) {
      this.faultForm.page = val ;
      this.getFault();
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}
::v-deep .dialog-footer {
  text-align: right;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
.control-item {

}
.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: absolute;
  right: 21px;
  top: 127px;
  z-index: 999;
}
.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}
.person {
  margin-bottom: 20px;
}
.history {
  text-align: right;
}
.record {
  margin-bottom: 0px;
  img {
    width: 15px;
    margin-right: 10px;
  }
  span{
    //display: inline-block;
    text-align: right;
    width: 100%;
    cursor: pointer;
  }
}
.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
    padding: 0;
    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }
    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    position: absolute;
    right: 0;
    font-size: 20px;
    //top: -15px;
    background: #fff;
    padding: 0;
    color: #f56c6c;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.item {
  padding:8px 10px;
  img{
    width: 32px;
    margin-right: 10px;
  }
}
.item:hover {
  background-color: #FFFAED;
}
.list-item {
  display: flex;
  justify-content: space-between;
  color: #9F9F9F;
  font-size: 14px;
  .name {
    color: #3D3D3D;
    font-size: 16px;
    margin-bottom: 10px;
  }
  padding: 15px 0;
  border-bottom: 1px solid #F1F1F1;
}
.item-left {
  display: flex;
  img{
    width: 56px;
    margin-right: 15px;
  }


}
</style>

